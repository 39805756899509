// Show login.tsx

import LoginRoute, { action as loginAction, loader as loginLoader } from "./login";
import { MetaFunction } from "@remix-run/node";

export const meta: MetaFunction = () => {
  return [{ title: "Vendur - Login" }, { name: "description", content: "Welcome to Vendur IO!" }];
};
export const loader = loginLoader;
export const action = loginAction;
export default LoginRoute;
